import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GoogleButton from 'react-google-button';
import { Copyright, AdministratorSignIn, BASignIn, DecisionScreen } from "../";
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const theme = createTheme();

function SignInSide(props)
{
	const { HandleAdminSignIn, HandleBASignIn } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const atRoot = location.pathname === "/";
	const [open, setOpen] = React.useState(false);

	const handleNoAccount = (event) =>
	{
		event.preventDefault();
		setOpen(true);
	}

	const handleClose = () =>
	{
		setOpen(false);
	};

	const handleBackClicked = (e) =>
	{
		e.preventDefault();
		navigate("/");
	};

	const unauthorized = props.unauthorized || false;

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100dvh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: 'url(https://picsum.photos/1920/1080)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', justifyContent: 'center', position: "relative" }}>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							width: "100%",
						}}
					>
						{!atRoot && <>
							<Link href="/login" onClick={handleBackClicked} variant="body2" sx={{ mt: 2, ml: 2, position: "absolute", left: "0px", top: "0px", textDecoration: "none" }}>
								<Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }}>
									<ChevronLeftIcon /> Back to Home
								</Typography>
							</Link>
						</>}
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in Required
						</Typography>
						{unauthorized &&
							<Typography component="h2" variant="h6" sx={{ color: 'red' }}>
								You do not have access to this page
							</Typography>
						}
						<Box component="form" noValidate sx={{ mt: 1 }}>
							<Routes>
								<Route path="/" element={<DecisionScreen />} />
								<Route path="/admin" element={<AdministratorSignIn HandleAdminSignIn={HandleAdminSignIn} />} />
								<Route path="/ba" element={<BASignIn HandleBASignIn={HandleBASignIn} />} />
							</Routes>
							<Grid container direction="column" alignItems="center" justifyContent="center">
								<Grid item>
									<Link href="#" variant="body2" onClick={handleNoAccount}>
										{"Don't have an account?"}
									</Link>
									<Dialog
										open={open}
										onClose={handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">
											{"Account Required"}
										</DialogTitle>
										<DialogContent>
											<DialogContentText id="alert-dialog-description">
												You must have an account to use this application. If you don't have an account, please contact your immediate manager/supervisor.
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={handleClose} autoFocus>Close</Button>
										</DialogActions>
									</Dialog>
								</Grid>
							</Grid>
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default SignInSide;