import GoogleButton from 'react-google-button'

function AdministratorSignIn(props)
{
	const handleSignIn = (event) =>
	{
		event.preventDefault();
		props.HandleAdminSignIn();
	};

	return (
		<GoogleButton onClick={handleSignIn} />
	);
}

export default AdministratorSignIn;
