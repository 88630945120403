import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Link, Button, Stack, Divider, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DecisionScreen(props)
{
	const navigate = useNavigate();

	const onAdminLoginClicked = (event) =>
	{
		navigate("/admin");
	};

	const onBALoginClicked = (event) =>
	{
		navigate("/ba");
	};

	return (
		<>
			<Grid container sx={{ flexDirection: "column", alignItems: "center", mt: 2 }} spacing={2}>
				<Grid item xs={12} textAlign="center">
					<Button variant="contained" onClick={onAdminLoginClicked} sx={{ width: "100%" }}>Administrator Login</Button>
				</Grid>
				<Grid item xs={12} textAlign="center">
					<Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
						<Box sx={{ borderTop: "1px solid #787878", width: "100%" }} />
						<Typography variant="h5" align='center' sx={{ color: "#787878" }}>
							or
						</Typography>
						<Box sx={{ borderTop: "1px solid #787878", width: "100%" }} />
					</Stack>
				</Grid>
				<Grid item xs={12} textAlign="center" sx={{ mb: 2 }}>
					<Button variant="contained" onClick={onBALoginClicked} sx={{ width: "100%" }}>Brand Ambassador Login</Button>
				</Grid>
			</Grid>
		</>
	);
}
export default DecisionScreen;