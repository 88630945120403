import React from "react";
import { useState, useEffect, useRef} from "react";
import TextField from '@mui/material/TextField';
import { Button, FormControlLabel, Checkbox, Typography } from "@mui/material";
import API from "../../API";

function BASignIn(props)
{
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("Invalid email or password.");

	useEffect(() =>
	{
		//check for cookie
	})

	const onEmailKeyDown = (event) =>
	{
		switch (event.keyCode)
		{
			case 27:
				event.preventDefault();
				setEmail("");
				setError(false);
			break;
			default:

			break;
		}
	};

	const onEmailChange = (event) =>
	{
		setEmail(event.target.value);
		setError(false);
	};

	const onPasswordKeyDown = (event) =>
	{
		switch (event.keyCode)
		{
			case 13:
				event.preventDefault();
				onSignInClicked(event);
			break;
			case 27:
				event.preventDefault();
				setPassword("");
				setError(false);
			break;
			default:

			break;
		}
	};

	const onPasswordChange = (event) =>
	{
		setPassword(event.target.value);
		setError(false);
	};

	const onSignInClicked = async (event) =>
	{
		event.preventDefault();

		var fd = new FormData();
		fd.append("email", email);
		fd.append("password", password);

		const response = await API.post("/user/balogin", fd, {
			headers: {
				"Content-Type": "multipart/form-data"
			},
		}).catch((err) =>
		{
			console.error(err);
			setError(true);
			setErrorMessage("Invalid email or password.");
		});

		if (response && response.status === 200)
		{
			//console.log(response);
			//redirect to dashboard at: /badashboard/
			//set cookie for "ba_account"

			document.cookie = "ba_account=" + response.data.result + "; path=/";

			window.location.href = response.data.redirect;
		}
		else if (response && response.status === 401 && response.data)
		{
			setErrorMessage(response.data.message);
		}
	};

	return (
		<>
			<TextField
				ref={emailRef}
				margin="normal"
				required
				fullWidth
				id="email"
				label="Email Address"
				name="email"
				autoComplete="email"
				autoFocus
				inputProps={{ enterKeyHint: "next" }}
				onKeyDown={onEmailKeyDown}
				value={email}
				onChange={onEmailChange}
			/>
			<TextField
				ref={passwordRef}
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				type="password"
				id="password"
				autoComplete="current-password"
				inputProps={{ enterKeyHint: "done" }}
				onKeyDown={onPasswordKeyDown}
				value={password}
				onChange={onPasswordChange}
			/>
			{error && <>
				<Typography variant="subtitle2" color="error">
					{errorMessage}
				</Typography>
			</>}
			<Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				onClick={onSignInClicked}
			>
				Sign In
			</Button>
		</>
	);
}

export default BASignIn;